@import '@tvg/ui-bootstrap/components/Less/variables.css';

.overlay h4 {
  font-family: var(--font-Univers-CondensedBold);
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  padding-bottom: 8px;
}

.overlay p {
  font-size: 12px;
  line-height: 1.17;
  color: var(--tvg-grey-dark);
}

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  border: 0;
  background: white;
  padding: 0;

  & svg {
    fill: var(--tvg-black);
    width: 16px;
    height: 16px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--tvg-overlay);
  z-index: 3;
}

.tootipbox {
  position: absolute;
  right: 3px;
  top: 120px;
  background: white;
  width: 240px;
  height: 80px;
  border-radius: 2px;
  padding: 18px 16px;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid white;
    right: 12px;
    bottom: -7px;
  }
}

@media (min-width: 800px) {
  .tootipbox {
    right: 50%;
    top: 105px;
    transform: translate(12px);
  }
}
