@import '../Less/variables.css';

.header {
  height: 48px;
  background: var(--tvg-blue-darker);
  display: flex;
  flex-flow: row nowrap;
  font-size: 11px;
  color: var(--tvg-blue-greyish);
  font-weight: normal;
  position: relative;
  text-transform: uppercase;

  & .headerLabel {
    border-right: 1px solid var(--tvg-blue-darkest);
    flex: 1 1 auto;
    line-height: 32px;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & svg.arrow {
      float: right;
      width: 15px;
      height: 16px;
      fill: var(--tvg-white);
      margin: 8px 0;
    }

    & .liveOn {
      padding-right: 4px;
      color: var(--tvg-blue-light);
    }
  }

  & .headerButton {
    width: 64px;
    height: 32px;
    margin: 8px;
    border: none;
    border-radius: 2px;
    background-color: var(--tvg-white-ghost);
    color: var(--tvg-white);
    flex: 0 0 auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}

.dropDownList {
  top: 48px;
  z-index: 50;
  left: 0;
  position: absolute;
  display: none;
  background-color: var(--tvg-white);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 32px;
  max-height: 360px;
  overflow-y: auto;
}

.dropDownChannelSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--tvg-black);
  border-bottom: 1px solid var(--tvg-blue-greyish);
  width: 320px;
  height: 32px;
  font-size: 12px;
  margin: 0;
  padding: 0 16px;
  box-sizing: border-box;
  cursor: pointer;

  & svg {
    padding: 0 5px;

    & path {
      fill: var(--tvg-grey-dark);
    }
  }

  &:nth-child(even) {
    background: var(--tvg-grey-lightest);
  }
}

.dropDownItem {
  width: 320px;
  color: var(--tvg-black);
  border-bottom: 1px solid var(--tvg-blue-greyish);
}

.dropDownLabel {
  background: var(--tvg-white);
  font-size: 12px;
  color: var(--tvg-grey-dark);
  padding: 0 16px;
  text-transform: uppercase;
}

.showDropDownList {
  display: block;
}

.videoWrapper {
  height: auto;
  width: 224px;
  text-align: center;
  position: relative;
}

.loginVideoWrapper {
  position: relative;
  width: 224px;
  text-align: center;
  height: 173px;
  background: url("../../assets/images/bg_race_replay.png") no-repeat;
  background-size: cover;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  & .overlay {
    height: 100%;
  }
}

.overlay,
.disableOverlay {
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  & svg {
    height: 40px;
    width: 40px;

    & path:first-of-type {
      fill: transparent;
    }

    & path {
      fill: var(--tvg-white);
    }
  }
}

.overlay {
  opacity: 0.8;
  background-color: var(--tvg-blue-darker);
}

.disableOverlay {
  opacity: 0.9;
  background-color: var(--tvg-blue-darkest);

  & path {
    fill-opacity: 0.5;
  }
}

.videoContainer {
  position: relative;
  display: flex;
  min-height: 125px;
  max-height: 168px;
  background: url("../../assets/images/bg_race_replay.png") no-repeat;
  background-size: cover;
}

.videoStyle {
  min-height: 125px;
  height: auto;
  background-color: var(--tvg-blue-darker);
  opacity: 1;

  & button {
    & svg {
      height: 40px;
      width: 40px;
    }
  }
}

.loginLabel {
  color: var(--tvg-white);
  font-size: 12px;
  font-weight: normal;
  padding-top: 25px;
  display: flex;
  flex-direction: row;
}

.loginLink {
  padding: 0 5px;
  color: #4491e1;
}
