@import '@tvg/ui-bootstrap/components/Less/variables.css';

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0;
  margin: 8px 0 0;
  font-size: 14px;
  font-family: var(--font-family-titling);
  font-weight: bold;
  text-transform: uppercase;
}

.separator {
  border-bottom: 1px solid var(--tvg-blue-lighter);
}
