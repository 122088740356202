@import '@tvg/ui-bootstrap/components/Less/variables.css';

:root {
  --default-gutter-size: 16px;
  --stopwatch-size: 14px;
  --stopwatchOverlaySize: 7px;
}

.raceWrapper {
  margin-bottom: 1px;
  height: 56px;
  overflow: hidden;
}

.greyhoundLabel {
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-top: 4px;
  color: var(--tvg-blue-light);
}

.mtpClass {
  font-size: 10px;
}

.onTvg {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--tvg-blue-light);
  font-weight: bold;
  margin-bottom: 4px;

  & span {
    margin-right: 6px;
  }

  & svg {
    fill: white
  }
}

.goToRace {
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 100%;
  margin: 0;
}

.lhnRace {
  background-color: var(--tvg-blue-darker);
  padding: 0 0 0 11px;
  text-transform: uppercase;
  height: 56px;
  display: flex;
  align-items: initial;
  justify-content: initial;
  flex-direction: row;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #304760;
    transition: background-color 0.3s ease-in-out;
  }

  & .raceOff {
    width: 32px;
    height: 24px;
    border-radius: 2px;
    background-color: var(--tvg-green);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  & .raceOffLabel {
    display: block;
    width: 32px;
    height: 18px;
    font-size: 10px;
    line-height: 0.9;
    letter-spacing: -0.3px;
    text-align: center;
    text-transform: uppercase;
  }

  & .leftSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    line-height: normal;
    flex: 0 0 42px;
    overflow: hidden;
    height: 56px;
  }

  & .live {
    font-size: 10px;
    line-height: 1em;
    padding: 2px 3px;
    font-weight: bold;
  }

  & .rightSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: normal;
    padding-left: 8px;
    padding-right: 16px;
    box-sizing: border-box;
    height: 56px;
    width: 100%;
  }

  & .raceStructLine {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
  }

  & .trackRaceName {
    align-items: baseline;
    justify-content: space-between;
  }

  & .description {
    color: var(--tvg-yellow);
    font-size: 12px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 130px;
    text-align: left;
    font-weight: bold;
  }

  & .description.lowerImportanceDescription {
    color: var(--tvg-blue-greyish);
    font-weight: 700;
  }

  & .trackName {
    font-family: var(--font-Univers-CondensedBold);
    color: var(--tvg-blue-greyish);
    letter-spacing: 0.2px;
    height: 12px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 125px;
    text-align: left;
    align-self: flex-start;
    display: inline-block;
  }

  & .raceNumber {
    font-family: var(--font-Univers-CondensedBold);
    height: 11px;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: var(--tvg-blue-greyish);
    display: flex;
  }

  & .liveTvgIcon {
    margin-left: 5px;
  }

  & .timeClass {
    color: var(--tvg-white);
    font-size: 12px;
    font-weight: bold;
  }
}

.highlighted {
  position: relative;
  overflow: hidden;
  background-color: var(--tvg-blue-darkest);

  &:hover {
    background-color: #304760;
    transition: background-color 0.3s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    width: 12px;
    height: 12px;
    overflow: hidden;
    background-color: var(--tvg-yellow);
    transform: rotate(45deg);
  }
}

.featuredLiveRace {
  height: 72px;

  & .lhnRace,
  & .leftSection,
  & .rightSection {
    height: 72px;
  }

  & .description.lowerImportanceDescription {
    margin-top: 4px;
  }
}
