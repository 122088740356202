@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  background-color: var(--tvg-white);
  box-shadow: 0 2px 0 0 var(--tvg-blue-lighter);
  margin-bottom: 16px;
  position: relative;
}

.favoriteTracksButton {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  color: var(--tvg-blue-bright);
  font-size: 11px;
  font-weight: bold;
  border: 0;
  height: 42px;
  padding: 0 8px;
  background: transparent;
  cursor: pointer;
  outline: none;
}
