@import '../../Less/variables.css';

.trackSelectorListLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 40px;
  padding-left: 8px;
  background-color: var(--color-mtp-far);
  font-family: var(--font-family-default);
  font-size: 11px;
  font-weight: bold;
  color: var(--tvg-grey-dark);
  border-bottom: 1px solid var(--tvg-white);
}
