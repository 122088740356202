@import "@tvg/ui-bootstrap/components/Less/variables.css";

:root {
  --titleColor: #194879;
  --leftBarWidth: 224px;
  --withLeftBar: 211px;
  --topBarHeight: 76px;
  --topBarHeight4nj: 68px;
  --centralSectionWidth: 1024px;
  --itemHeight: 40px;
}

@media (max-width: 1255px) {
  .leftNavBar.leftNavBarHide {
    left: -999px;
    height: calc(100% - var(--topBarHeight));
    top: var(--topBarHeight);
  }

  .leftNavBar.leftNavBarHide.nj {
    height: calc(100% - var(--topBarHeight4nj));
    top: var(--topBarHeight4nj);
  }
}

@media (min-width: 1190px) and (max-width: 1255px) {
  .leftNavBar.leftNavBarHide.showLhnOnHamburguer {
    left: 0;
  }
}

@media (max-width: 1190px) {
  .leftNavBar.leftNavBarHide.showLhnOnHamburguer {
    left: 253px;
  }
}

.leftNavBar {
  font-family: var(--font-family-default);
  font-size: var(--font-size-normal);
  position: fixed;
  width: var(--leftBarWidth);
  top: var(--topBarHeight);
  bottom: 0;
  left: 0;
  z-index: 1000;
  transition: left 0.4s;
  transition-timing-function: linear;

  &.nj {
    height: calc(100% - var(--topBarHeight4nj));
    top: calc(var(--topBarHeight4nj) + 8px);
  }

  & > nav {
    border-radius: 4px;
    background-color: var(--tvg-blue-darkest);
    display: flex;
    flex: 0 0;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  & .leftBarFeaturedRaces {
    max-height: calc(100% - var(--itemHeight));
    width: var(--leftBarWidth);
  }

  & .leftNavContent {
    border-radius: 4px;
    display: flex;
    flex: 0 100 100%;
    flex-direction: column;
    background-color: var(--tvg-blue-darkest);
    height: 100%;
    min-height: 48px;
  }

  & * {
    box-sizing: border-box;
    vertical-align: baseline;
  }
}

@media (min-width: 1268px) {
  .leftNavBar {
    left: 50%;
    transform: translate3d(-628px, 0, 0);
  }
}

.quickLinksContainer {
  background-color: #112a44;
}
