.raceList {
  display: block;
  padding: 0;
}

.animateListItem {
  box-sizing: border-box;
  line-height: 30px;
  list-style: none;
  overflow: hidden;
}

.animateListItemLeave {
  opacity: 1;
}

.animateListItemLeave.animateListItemLeaveActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.loadingLayer {
  background-color: black;
  opacity: 0.5;
  display: block;
  margin: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;

  &.hiddenLoading {
    display: none;
  }
}
