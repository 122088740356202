@import '@tvg/ui-bootstrap/components/Less/variables.css';

:root {
  --dropSideWidth: 320px;
  --dropSideHeight: 360px;
}

.dropSideMainWrapper {
  position: absolute;
  display: none;
  top: 0;
  right: -var(--dropSideWidth);
  width: var(--dropSideWidth);
  height: var(--dropSideHeight);

  &::before {
    content: " ";
    position: absolute;
    top: 20px;
    bottom: initial;
    left: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 7px 0;
    border-color: transparent var(--tvg-white) transparent transparent;
    z-index: 1;
    display: none;
  }
}

.upsideDown {
  &::before {
    bottom: 20px;
    top: initial;
  }
}

.customDropDownList.customStyling {
  top: 0;
}

.dropSideMainWrapperVisible {
  display: block;

  &::before {
    display: block;
  }
}

.dropDownLabel {
  background-color: var(--tvg-white);
  font-size: 14px;
  padding: 0 16px;
  text-transform: uppercase;
  user-select: none;
  font-family: var(--font-Univers-CondensedBold);
}
