@import "@tvg/ui-bootstrap/components/Less/variables.css";

:root {
  --leftBarWidth: 224px;
  --withLeftBar: 211px;
  --topBarHeight: 48px;
  --centralSectionWidth: 1024px;
  --itemHeight: 40px;
}

.tabSystem {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabsWrapper {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex: 0 0 48px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 48px;
  overflow: hidden;
  background-color: var(--tvg-blue-darkest);
}

.tabLabel {
  color: var(--tvg-blue-lighter);
  font-size: 12px;
  font-weight: bold;
  text-transform: capitalize;
  transition: color 0.3s ease-in-out;
}

.tabIcon {
  fill: var(--tvg-blue-lighter);
  transition: fill 0.3s ease-in-out;
  margin-right: 7px;
}

.buttonFlexWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.greyhoundTab {
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  font-family: var(--font-Univers-CondensedBold);
  font-size: 16px;
  text-align: left;
  text-transform: uppercase;

  & .text {
    padding: 0 8px;
    display: inline-block;
  }

  & .icon {
    width: 32px;
    height: 20px;
    vertical-align: sub;
  }
}

.tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 46px;
  cursor: pointer;
  border-bottom: 2px solid var(--tvg-white-ghost);

  &:hover {
    & button:not(.activeTab) .tabLabel {
      transition: color 0.3s ease-in-out;
      color: var(--tvg-white);
    }

    & button:not(.activeTab) .tabIcon {
      transition: fill 0.3s ease-in-out;
      fill: var(--tvg-green);
    }
  }

  &.activeTab {
    border-bottom: 2px solid var(--tvg-green);

    & .tabLabel {
      color: white;
    }

    & .tabIcon {
      fill: var(--tvg-green);
    }
  }
}

.tabIcon {
  color: var(--tvg-blue-lighter);
}

.tabsContentWrapper {
  display: flex;
  flex: 0 100 100%;
  flex-direction: column;
  background-color: var(--tvg-blue-midnight);
  overflow: hidden;
  height: auto;
  min-height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  border-radius: 4px;
}

.tabContent {
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
  visibility: hidden;
  height: 0;

  &.tabShow {
    height: auto;
    visibility: visible;
  }
}

.tab .selectTab {
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;

  &:disabled {
    cursor: default;
    background: rgba(255, 255, 255, 0.1);

    &:hover .tabLabel,
    & .tabLabel {
      color: var(--tvg-grey);
    }

    &:hover .tabIcon,
    & .tabIcon {
      fill: var(--tvg-grey);
    }
  }
}

@media (max-width: 1255px) {
  .tabsWrapper {
    display: none;
  }

  .tabsContentWrapper {
    display: flex;

    & .tabContent,
    & .tabContent.tabShow {
      height: 0;
      visibility: hidden;
    }

    & :nth-child(1).tabContent.tabShow {
      height: auto;
      visibility: visible;
    }
  }
}

.scrollBarDisabled > div {
  /* stylelint-disable */
  overflow: hidden !important;
  /* stylelint-enable */
  margin: 0;
}

@media (max-width: 968px) {
  .scrollBar {
    height: 100%;
  }
}
