@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px;
  background-color: var(--tvg-blue-whiteish);
  box-shadow: inset 0 1px 0 0 var(--tvg-blue-lighter);

  & > button {
    flex-grow: 1;
    flex-basis: 0;

    &:first-child {
      margin-right: 4px;
    }

    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}
