@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--tvg-blue-dark);
}

.title {
  font-family: var(--font-Univers-Condensed);
  font-size: 18px;
  font-weight: bold;
  color: var(--tvg-white);
  margin-left: 8px;
}

.close {
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 40px;
  height: 48px;
  outline: none;
}

.icon {
  display: block;
  width: 16px;
  height: 16px;
  margin: auto;
  fill: var(--tvg-white);
}
