@import '@tvg/ui-bootstrap/components/Less/variables.css';

.RaceFiltersContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 0;
  background-color: var(--tvg-white);
  z-index: 3;
  transition: transform 450ms cubic-bezier(0.34, 0, 0.43, 0.99);
  display: flex;
  flex-direction: column;

  @media (max-width: 799px) {
    left: 0;
    transform: translate(0, 100%);
  }

  @media (min-width: 800px) {
    width: 0;
    transform: translate(100%, 0);
  }
}

.RaceFiltersContainerIn {
  transform: translate(0, 0);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.5);
  height: 100%;

  @media (min-width: 800px) {
    width: 320px;
  }
}

.RaceFiltersCenterContainer {
  flex-grow: 1;
  overflow: auto;
  background-color: var(--tvg-blue-greyish);
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 2;

  &::after {
    display: block;
    content: 'applying filters';
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    color: var(--tvg-white);
    font-size: 14px;
    font-weight: bold;
    z-index: 10;
    transform: translate(0, 30px);
  }
}
