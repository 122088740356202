.leave {
  transform: translate(0, 0);
  overflow: hidden;
  max-height: 56px;
}

.leaveActive {
  transform: translate(-100%, 0);
  max-height: 0;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, max-height 0.3s ease-in-out 0.3s;
}
