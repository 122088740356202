@import '@tvg/ui-bootstrap/components/Less/variables.css';

.WrapperContainer {
  position: relative;
}

.RaceFiltersContainer {
  position: absolute;
  top: 5px;
  left: 100%;
  margin: -8px 0 0 4px;
  background-color: var(--tvg-white);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.2);
}

.RaceFiltersContainerIn {
  width: 320px;

  &::before {
    content: '';
    position: absolute;
    top: 19px;
    right: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 7px 0;
    border-color: transparent var(--tvg-white) transparent transparent;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 2;

  &::after {
    display: block;
    content: 'applying filters';
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    color: var(--tvg-white);
    font-size: 14px;
    font-weight: bold;
    z-index: 10;
    transform: translate(0, 30px);
  }
}
