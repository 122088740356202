@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  padding: 12px;
  color: var(--tvg-white);
  font-family: var(--font-family-default);
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
}

.icon {
  width: 32px;
  height: 32px;
  margin: 12px 12px 8px;
  fill: var(--tvg-yellow);
}

.label {
  display: block;
}

.button {
  border: none;
  margin-top: 24px;
  text-transform: uppercase;
  width: 100%;
  height: 32px;
  border-radius: 2px;
  background-color: var(--tvg-white-ghost);
  font-size: 11px;
  font-weight: bold;
  color: var(--tvg-white);

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;
  }
}
