@import '../Less/variables.css';

.dropDownComponent {
  position: relative;
  display: block;
  background-color: var(--tvg-white);
  padding-bottom: 32px;
  max-height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropDownChannelSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--tvg-black);
  border-bottom: 1px solid var(--tvg-blue-greyish);
  width: 320px;
  height: 32px;
  font-size: 12px;
  margin: 0;
  padding: 0 16px;
  box-sizing: border-box;
  cursor: pointer;

  & svg {
    padding: 0 5px;

    & path {
      fill: var(--tvg-grey-dark);
    }
  }

  &:nth-child(even) {
    background: var(--tvg-grey-lightest);
  }
}

.dropDownItem {
  width: 320px;
  color: var(--tvg-black);
  border-bottom: 1px solid var(--tvg-blue-greyish);
}

.dropDownLabel {
  background: var(--tvg-white);
  font-size: 12px;
  color: var(--tvg-grey-dark);
  padding: 0 16px;
  text-transform: uppercase;
}
