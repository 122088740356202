@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: var(--tvg-blue-whiteish);
  border-top: 1px solid var(--tvg-blue-greyish);

  & > button {
    flex-basis: 0;

    &:first-child {
      flex-grow: 0;
      margin-right: 4px;
    }

    &:not(:first-child) {
      margin-left: 4px;
      flex-grow: 1;
    }
  }
}
