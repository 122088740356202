@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  height: 49px;
  margin-bottom: 1px;
  border-bottom: 1px solid var(--tvg-white-ghost);
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.containerWithFilters {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.FilterButton {
  width: 100%;
  padding: 0;
  border: 0 none;
  border-radius: 2px;
  background-color: var(--tvg-white-ghost);
  color: var(--tvg-white);
  outline: none;
  cursor: pointer;

  & > span {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.FilterButtonIcon {
  fill: var(--tvg-white);
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.FilterButton__compact {
  width: 24px;
  height: 24px;

  & > span {
    width: 24px;
    height: 24px;
  }

  & .FilterButtonIcon {
    margin: 0;
  }
}

.FilterButton__new {
  position: relative;

  &::after {
    display: block;
    height: 16px;
    line-height: 16px;
    padding: 0 3px;
    border-radius: 2px;
    background-color: var(--tvg-yellow);
    content: 'new';
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: var(--tvg-grey-dark);
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.filters {
  line-height: 16px;
  font-size: 11px;

  & dt {
    color: var(--tvg-blue-light);
    font-weight: bold;
    margin-bottom: 2px;
  }

  & dd {
    margin-top: 2px;
    color: var(--tvg-blue-greyish);
  }
}
