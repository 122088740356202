@import '../../Less/variables.css';

.trackSelectorListLi {
  display: flex;
  align-items: center;
  height: 32px;
  font-size: 12px;
  margin: 0;

  & a {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    cursor: pointer;
  }

  &:nth-of-type(odd) {
    background-color: var(--tvg-grey-lightest);
  }

  &:nth-of-type(even) {
    background-color: var(--tvg-white);
  }
}

.trackSelectorListName {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 16px;
  padding-right: 2px;
  box-sizing: border-box;
}

.trackSelectorListLiHarness {
  margin-left: auto;
  font-size: 12px;
}

.trackSelectorListLiRace {
  margin: 0 5px;
  padding-right: 5px;
  box-sizing: border-box;

  & strong {
    font-family: var(--font-family-default);
    font-weight: bold;
    margin-right: 2px;
  }
}

.trackSelectorListMTP {
  display: flex;
  justify-content: flex-start;
  width: 20%;
  text-align: right;
  font-size: 12px;
  box-sizing: border-box;
  margin-right: 16px;
}
