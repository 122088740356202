@import '@tvg/ui-bootstrap/components/Less/variables.css';

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 42px;
  padding: 0 8px;
  font-size: 16px;
  font-family: var(--font-Univers-Condensed);
  color: var(--tvg-blue-dark);
  font-weight: bold;
  text-transform: uppercase;
}
