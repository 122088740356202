@import '@tvg/ui-bootstrap/components/Less/variables.css';

.featuredTitle {
  display: flex;
  flex: 0 0 32px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 32px;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: var(--tvg-blue-midnight);
}

.featuredTitleLabel {
  color: var(--tvg-yellow);
  font-family: var(--font-Univers-CondensedBold);
  font-size: 14px;
  text-transform: uppercase;
}

.showHideButton {
  color: var(--tvg-blue-greyish);
  background: transparent;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.featuredWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.5s linear, height 0.5s linear;
  position: relative;
  z-index: 1;

  &.showMore {
    height: 100%;
    transition: max-height 0.5s linear, height 0.5s linear;
  }

  &.showLess {
    height: 203px;
  }
}

.featuredRaceList {
  background-color: var(--tvg-blue-midnight);
  padding: 0;
  margin: 0;

  &.featuredVisible {
    display: block;
  }
}
