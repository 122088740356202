@import '../Less/variables.css';

.mtp {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: transparent;
  border-radius: 2px;

  &.mtp-near {
    background-color: var(--color-mtp-near);
  }

  &.mtp-med {
    background-color: var(--color-mtp-med);
  }

  &.mtp-far {
    background-color: var(--color-mtp-far);
  }

  &.mobile {
    border-radius: 0;
    justify-content: flex-start;

    & .iconMtp {
      margin-top: 21px;
      height: 24px;
      width: 24px;
    }

    & .label {
      margin-top: 0;
    }

    & .time {
      margin-top: 50%;
    }

    & .live {
      margin-top: 50%;
      font-size: 12px;
    }
  }

  &.cancelled {
    .label {
      font-size: 8px;
    }
  }
}

.iconMtp {
  height: 40px;
  width: 40px;
  fill: var(--tvg-white);
  margin: 0 0 8px;

  &.inline {
    height: 16px;
    width: 16px;
    fill: var(--tvg-black);
    margin: 0 0 5px;
  }

  &.fillGrey {
    fill: var(--tvg-grey);
  }

  &.noIcon {
    display: none;
  }
}

.label {
  margin-top: 6px;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
}

.live {
  font-size: 14px;
  font-weight: bold;
  background-color: var(--tvg-green);
  text-align: center;
  color: var(--tvg-white);
  border-radius: 3px;
  padding: 5px;
  line-height: 0.93;
  letter-spacing: -0.4px;
}

.time {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: var(--tvg-grey-dark);
  text-transform: uppercase;
}

.minutes {
  font-size: 14px;
  font-weight: bold;
  text-transform: lowercase;
}
