@import "../Less/variables.css";

.Video {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  position: relative;

  &:hover {
    & svg.popout {
      display: block;
    }
  }

  & svg {
    height: 25%;
    width: 25%;
    max-height: 46px;
    max-width: 46px;

    & path:first-of-type {
      fill: transparent;
    }

    & path {
      fill: var(--tvg-white);
    }
  }
}

svg.popout {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  z-index: 20;
  height: 16px;
  width: 16px;

  & path:first-of-type {
    fill: var(--tvg-white);
  }

  & path {
    fill: var(--tvg-white);
  }
}

.VideoPlayButton {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
}

.VideoPlayer {
  background-color: var(--tvg-black);
}
