@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  padding: 0 16px;
}

.soon {
  margin: 8px 0 24px;
  color: var(--tvg-grey);
}
