@import '../../Less/variables';

.checkbox {
  & label {
    display: inline-flex;
    height: 24px;
    align-items: center;
    color: var(--tvg-grey-dark);
    font-size: 12px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 2px;
      background-color: var(--tvg-white);
      box-shadow: inset 0 0 0 1px var(--tvg-blue-bright);
      margin-right: 8px;
      cursor: pointer;

      @media (max-width: 767px) {
        width: 30px;
      }

      @media (max-width: 376px) {
        width: 35px;
      }
    }

    & svg {
      opacity: 0;
      transition: opacity 0.2s cubic-bezier(0, 0.77, 0.94, 1.13);
      position: absolute;
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  & input {
    width: 0;
    height: 0;
    opacity: 0;
    display: none;
  }
}

.checkbox input:checked + label svg {
  opacity: 1;
}

.checkbox.checkbox__mobile {
  & label {
    width: 100%;
    height: 44px;
    padding: 0 8px;
    flex-direction: row-reverse;
    justify-content: space-between;

    &::before {
      margin-right: 0;
    }

    & svg {
      left: auto;
      right: 12px;
    }
  }
}
