@import '../../Less/variables.css';

.LoginContainer {
  background: rgba(0, 0, 0, 0.8);
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: var(--tvg-white);
  padding: 0;
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  &:focus {
    outline: none;
  }

  & svg {
    height: 25%;
    width: 25%;
    max-height: 46px;
    max-width: 46px;
  }

  & path:first-of-type {
    fill: transparent;
  }

  & path {
    fill: var(--tvg-white);
  }
}

.LoginTitle {
  font-family: var(--font-Univers-Condensed);
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 24px;
}

.LoginDescription {
  font-family: var(--font-family-default);
  font-size: 12px;
  text-align: center;
  margin-bottom: 16px;

  & .LoginLabel {
    color: var(--color-info);
  }
}
