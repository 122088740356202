@import '@tvg/ui-bootstrap/components/Less/variables.css';

.container {
  position: relative;
  padding: 16px 16px 8px;
}

.title {
  font-family: var(--font-family-titling);
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
  margin-bottom: 4px;
}

.subTitle {
  font-size: 12px;
  color: var(--tvg-grey);
  line-height: 14px;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  outline: none;
}

.icon {
  display: block;
  width: 16px;
  height: 16px;
  fill: var(--tvg-grey-dark);
}
